import axios from "axios"

export const geoapifyKEY = '988bd84f3d5f497eb4f5486c037a0b6a'

export const geoapifyAPI = axios.create({
  baseURL: 'https://api.geoapify.com/v1'
})

// const baseApiUrl = 'http://192.168.30.55/app-sales-test/public'
const baseApiUrl = 'https://api.server.pasmodsentry.com/mrvit/public'

export const serverAPI = axios.create({
  baseURL: baseApiUrl
})
export const imgClientUrl = `${baseApiUrl}/src/images/client`
export const imgProfileUrl = `${baseApiUrl}/src/images/profile`
export const imgAbsenUrl = `${baseApiUrl}/src/images/absen`

export const tokenIsFalse = (resMsg) => {
  if (
    resMsg === 'Token Required' ||
    resMsg === 'Invalid Token' ||
    resMsg === 'Expired Token'
  )
    return true
  else
    return false
}
